@font-face {
  font-family: icomoon;
  src: url("icomoon.18ed2820.eot");
  src: url("icomoon.18ed2820.eot#iefix") format("embedded-opentype"), url("icomoon.2ff25cdb.ttf") format("truetype"), url("icomoon.13514712.woff") format("woff"), url("icomoon.e4dbdea2.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-save:before {
  content: "";
}

.icon-transform:before {
  content: "";
}

.icon-material:before {
  content: "";
}

.icon-undo:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-puzzle:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-snow:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-question:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-exclamation:before {
  content: "";
}

.icon-warning:before {
  content: "";
}

.icon-plane:before {
  content: "";
}

.icon-stats:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-clipboard:before, .icon-paste:before {
  content: "";
}

.icon-document:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-calculator:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-ship:before {
  content: "";
}

.icon-examples:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-ham:before {
  content: "";
}

.icon-external:before {
  content: "";
}

.icon-atom:before {
  content: "";
}

.icon-rail:before {
  content: "";
}
/*# sourceMappingURL=9d8a8df2-4648-40c5-93df-b7b9607bfbba.7b95cc00.css.map */
